/* eslint-disable import/no-anonymous-default-export */
import moment from 'moment';

class Auth {
  state = {
    usernameList: [
      { username: 'bporter@nfuzionit.com', password: 'sB792erac!' },
      { username: 'tim.clark@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'scott.morris@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'jenniffer@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'katie.hagan@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'scott.walker@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'kim.molkoski@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'pat.comley@onpointwarranty.com', password: 'sB792erac!' },
      { username: 'guerra@gugcs.org', password: 'Cesar!35' },
      { username: 'delossantos@gugcs.org', password: 'Rich@34' },
      { username: 'ted.newman@perrylocal.org', password: 'TeDPerry!2' },
      { username: 'llca.heathern@lindsaylane.org', password: 'llc@Linds3y' },
      { username: 'bauseman@gugcs.org', password: 'B@us3Man' },
      { username: 'siriannib@bcasd.net', password: 'P4tr!ot5' },
      { username: 'mwhitnall@idreampcs.org', password: 'f!r3W0rk5' },
      { username: 'llca.brandon@lindsaylane.org', password: 'DVJwY6?a6?kZ' },
      { username: 'Michael.Lewis@llcalions.org', password: 'ogt<yU4PpY' },
    ],
  };

  login(usernameInput, passwordInput, successCB, failCB) {
    let userDetail = {};
    let userRecord = this.state.usernameList.find(function (user) {
      return user.username.toUpperCase() === usernameInput.toUpperCase();
    });

    userDetail =
      userRecord != null && Object.keys(userRecord).length > 0
        ? userRecord
        : {};

    if (Object.keys(userDetail).length > 0) {
      if (passwordInput === userDetail.password) {
        sessionStorage.setItem('email', usernameInput);
        sessionStorage.setItem('isAuthenticated', true);
        sessionStorage.setItem('loginDt', moment());
        successCB();
      } else {
        sessionStorage.clear();
        sessionStorage.setItem('isAuthenticated', false);
        failCB();
      }
    } else {
      sessionStorage.clear();
      sessionStorage.setItem('isAuthenticated', false);
      failCB();
    }
  }

  logout(cb) {
    sessionStorage.clear();
    sessionStorage.setItem('isAuthenticated', false);
    cb();
  }

  isAuthenticated() {
    let today = moment();
    let year = today.get('year');
    let month = today.get('month') + 1;
    let date = today.get('date');

    let loginDt = sessionStorage.getItem('loginDt');
    let loginYear = 0;
    let loginMonth = 0;
    let loginDate = 0;

    if (loginDt) {
      loginYear = moment(this.loginDt).get('year');
      loginMonth = moment(this.loginDt).get('month') + 1;
      loginDate = moment(this.loginDt).get('date');
    }

    let isAuthenticated = sessionStorage.getItem('isAuthenticated');

    if (
      isAuthenticated &&
      loginYear === year &&
      loginMonth === month &&
      loginDate === date
    ) {
      return true;
    } else {
      sessionStorage.clear();
      sessionStorage.setItem('isAuthenticated', false);
      return false;
    }
  }
}

export default new Auth();